import React, {lazy, Suspense} from 'react'
import {Route, Redirect, Switch, Router} from 'react-router-dom'
import {CSSTransition, SwitchTransition} from 'react-transition-group'
import {connect} from 'react-redux'
import AppSettings from "./redux/Tracefully/AppSettings";

const routes = [
    // Index
    {
        path: '/',
        Component: lazy(() => import('./pages/index')),
        exact: true,
    },

]

const mapStateToProps = ({AppSettings}) => ({
    routerAnimation: AppSettings.settings.routerAnimation,
})

const RouteRouter = ({history, routerAnimation}) => {
    return (
        <Router history={history}>
            <Route
                render={state => {
                    const {location} = state
                    return (


                        <Switch location={location}>
                                    {routes.map(({path, Component, exact}) => (
                                        <Route
                                            path={path}
                                            key={path}
                                            exact={exact}
                                            render={() => {
                                                return (



                                                    <Suspense fallback={null}>
                                                            <Component location={location}/>
                                                    </Suspense>

                                                )
                                            }}
                                        />
                                    ))}
                                    <Redirect to="/"/>
                                </Switch>


                    )
                }}
            />
        </Router>
    )
}

export default connect(mapStateToProps)(RouteRouter)
