export default function UserSettings(state = {
    User: {},
    Token: {
        UserToken: null,
        AdminToken: null
    },

}, action) {
    switch (action.type) {

        case CHANGE_USERTOKEN:
            return {
                ...state,
                Token: {
                    ...state.Token,
                    UserToken: action.changeUserToken
                }
            };
        case CHANGE_USER:

            return {
                ...state,
                User:action.changeUser

            };

        case CHANGE_CLEARTOKEN:
            console.log('CLEAR TOKENS');
            return {
                ...state,
                Token: {
                    UserToken:null,
                    AdminToken: null
                }
            };
        case CHANGE_SHOPS:
            return {
                ...state,
                Shops: action.changeShops

            };
    }
    return state;
}

export const INIT_DATEINFO = 'UserSettings/INIT_DATEINFO';
export const CHANGE_USERTOKEN = 'UserSettings/CHANGE_USERTOKEN';
export const CHANGE_USER = 'UserSettings/CHANGE_USER';
export const CHANGE_ADMINTOKEN = 'UserSettings/CHANGE_ADMINTOKEN';
export const CHANGE_CLEARTOKEN = 'UserSettings/CHANGE_CLEARTOKEN';

export const CHANGE_SHOPS = 'UserSettings/CHANGE_SHOPS';

export const setUserToken = changeUserToken => ({
    type: CHANGE_USERTOKEN,
    changeUserToken
});

export const setUser = changeUser => ({
    type: CHANGE_USER,
    changeUser
});

export const setAdminToken = changeAdminToken => ({
    type: CHANGE_ADMINTOKEN,
    changeAdminToken
});

export const clearTokens = clearTokens => ({
    type: CHANGE_CLEARTOKEN,
    clearTokens
});



