
export default function AppSettings (state = {
    viewportWidth:0,
    trackingNrArr:[],
    translationSelectorState:false,
    translationTargetLanguage:{
        geo:'en',
        name:'English'
    },
    settings:{
        logo: 'Tracefully',
        locale: 'en-US',
        isSidebarOpen: false,
        isSupportChatOpen: false,
        isMobileView: false,
        isMobileMenuOpen: false,
        isMenuCollapsed: false,
        menuLayoutType: 'left', // left, top, nomenu
        routerAnimation: 'slide-fadein-up', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
        menuColor: 'gray', // white, dark, gray
        theme: 'default', // default, dark
        authPagesColor: 'image', // white, gray, image
        primaryColor: '#007DF6',
        leftMenuWidth: 256,
        isMenuUnfixed: false,
        isMenuShadow: false,
        isTopbarFixed: false,
        isGrayTopbar: false,
        isContentMaxWidth: false,
        isAppMaxWidth: false,
        isGrayBackground: false,
        isCardShadow: true,
        isSquaredBorders: false,
        isBorderless: false,
    }

}, action) {
    switch (action.type) {
        case CHANGE_VIEWPORTWIDTH:
            return {
                ...state,
                viewportWidth:action.changeViewPortWidth.viewportWidth
            };
        case CHANGE_TRACKINGNRARR:
            return {
                ...state,
                trackingNrArr:action.changeTrackingNrArr
            };
        case CHANGE_TRANSLATIONSELECTORSTATE:
            return {
                ...state,
                translationSelectorState:action.changeTranslationSelectorState
            };
        case CHANGE_TRANSLATIONTARGETLANGUAGE:
            return {
                ...state,
                translationTargetLanguage:action.changeTranslationTargetLanguage
            };
    }
    return state;
}

export const CHANGE_VIEWPORTWIDTH = 'AppSettings/CHANGE_VIEWPORTWIDTH';
export const CHANGE_TRACKINGNRARR = 'AppSettings/CHANGE_TRACKINGNRARR';
export const CHANGE_TRANSLATIONSELECTORSTATE = 'AppSettings/CHANGE_TRANSLATIONSELECTORSTATE';
export const CHANGE_TRANSLATIONTARGETLANGUAGE = 'AppSettings/CHANGE_TRANSLATIONTARGETLANGUAGE';


export const changeViewPortWidth = changeViewPortWidth => ({
    type: CHANGE_VIEWPORTWIDTH,
    changeViewPortWidth
});


export const changeTrackingNrArr = changeTrackingNrArr => ({
    type: CHANGE_TRACKINGNRARR,
    changeTrackingNrArr
});

export const changeTranslationSelectorState = changeTranslationSelectorState => ({
    type: CHANGE_TRANSLATIONSELECTORSTATE,
    changeTranslationSelectorState
})

export const changeTranslationTargetLanguage = changeTranslationTargetLanguage => ({
    type: CHANGE_TRANSLATIONTARGETLANGUAGE,
    changeTranslationTargetLanguage
})




