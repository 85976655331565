import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import UserSettings from './Tracefully/UserSettings'
import AppSettings from './Tracefully/AppSettings'

export default history =>
    combineReducers({
        router: connectRouter(history),
        UserSettings,
        AppSettings,
    })
