import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import {parse, stringify} from 'flatted';
import history from '../../history'
// import createHistory from 'history/createBrowserHistory';
// import {history} from 'react-router-dom'
// import thunk from "redux-thunk"
import rootReducer from '../reducers'

const routeMiddleware = routerMiddleware(history)

const middlewares = [ routeMiddleware]
const CurrentVersion = '0.6'

const loadState = () => {
  try {
    // check if version matches or not ...
    // for first time localStorage.getItem('appVersion') will be null
    const storedVersion = localStorage.getItem('appVersion')

    if (CurrentVersion !== storedVersion) {
      if (storedVersion == null) {
        localStorage.removeItem('TracefullyState') //
        // update the appVersion in LS with current version
        localStorage.setItem('appVersion', CurrentVersion)
        return {}
      } else {
        // let serializedState = JSON.parse(localStorage.getItem('TracefullyState'))
        localStorage.removeItem('TracefullyState') //
        // update the appVersion in LS with current version
        localStorage.setItem('appVersion', CurrentVersion)
        return {}
      }
    } else {
      const serializedState = localStorage.getItem('TracefullyState')
      if (serializedState === null) {
        return {}
      }
      return parse(serializedState)
    }
  } catch (err) {
    console.log(err)
    return {}
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedState = loadState(CurrentVersion)

const store = createStore(
  rootReducer(history),
  persistedState,
  composeEnhancers(applyMiddleware(...middlewares)),
)

export { store, history }
