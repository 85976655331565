// import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.less' // app & third-party component styles
import React from 'react'
import ReactDOM from 'react-dom'
import {parse, stringify} from 'flatted';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store } from './redux/storeConfig/store'
import history from './history'
import Router from './router'
import * as serviceWorker from './serviceWorker'



ReactDOM.render(
  <Provider store={store}>
      <Router history={history} />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
